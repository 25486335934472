@font-face {
 font-family: "URW DIN";
 src: url("/fonts/urw-din/URWDIN-Regular.otf") format("opentype");
 font-weight: normal;
 font-style: normal;
 font-display: swap;
}

@font-face {
 font-family: "URW DIN";
 src: url("/fonts/urw-din/URWDIN-Bold.otf") format("opentype");
 font-weight: bold;
 font-style: normal;
 font-display: swap;
}

@font-face {
 font-family: "URW DIN";
 src: url("/fonts/urw-din/URWDIN-Italic.otf") format("opentype");
 font-weight: normal;
 font-style: italic;
 font-display: swap;
}

@font-face {
 font-family: "URW DIN SemiCond";
 src: url("/fonts/urw-din-semicond/URWDINSemiCond-Regular.otf") format("opentype");
 font-weight: normal;
 font-style: normal;
 font-display: swap;
}

@font-face {
 font-family: "URW DIN SemiCond";
 src: url("/fonts/urw-din-semicond/URWDINSemiCond-Demi.otf") format("opentype");
 font-weight: 500;
 font-style: normal;
 font-display: swap;
}

$font-family-sans-serif: 'URW DIN', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$headings-font-family: 'URW DIN SemiCond', $font-family-sans-serif;

$enable-rounded: false;

$navbar-nav-link-padding-x: 1rem;

$container-max-widths: (
  xl: 1420px
);

// Type Scale - Major Second
// https://type-scale.com/

body {
  font-size: 1rem !important;
}

h1 {
  font-size: 1.424rem;
}

@media (min-width: 800px) {
  body {
    font-size: 1.266rem !important;
  }

  h1 {
    font-size: 1.602rem;
  }
}

@media (min-width: 1200px) {
  body {
    font-size: 1.424rem !important;
  }

  h1 {
    font-size: 1.802rem;
  }
}
