// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "bootstrap/functions";

// 2. Include any default variable overrides here
@import "variables";

// 3. Include remainder of required Bootstrap stylesheets
@import "bootstrap/variables";
@import "bootstrap/variables-dark";

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "bootstrap/maps";
@import "bootstrap/mixins";
@import "bootstrap/root";

// 6. Optionally include any other parts as needed
@import "bootstrap/utilities";
@import "bootstrap/reboot";

@import "bootstrap/buttons";
@import "bootstrap/close";
@import "bootstrap/containers";
@import "bootstrap/images";
@import "bootstrap/grid";
@import "bootstrap/nav";
@import "bootstrap/navbar";
@import "bootstrap/offcanvas";
@import "bootstrap/type";

@import "bootstrap/helpers";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "bootstrap/utilities/api";

// 8. Add additional custom code here

@import "hero";

.swissflag {
  height: 1.424rem;
}

@media (min-width: 800px) {
  .swissflag {
    height: 1.602rem;
  }
}

@media (min-width: 1200px) {
  .swissflag {
    height: 1.802rem;
  }
}


.swiper-button-prev,
.swiper-button-next {
  color: white;
}

.swiper-pagination-bullet-active {
  background: white;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.swiper-slide--1 {
  background-image: url('/img/slider/touring.jpg');
}

.swiper-slide--2 {
  background-image: url('/img/slider/instruments-de-musique.jpg');
}

.swiper-slide--3 {
  background-image: url('/img/slider/synthetiseurs.jpg');
}

.swiper-slide--4 {
  background-image: url('/img/slider/velo-cargo.jpg');
}

.swiper-slide--5 {
  background-image: url('/img/slider/vinyls.jpg');
}

.swiper-slide--6 {
  background-image: url('/img/slider/installation-fixe.jpg');
}

.swiper-slide--7 {
  background-image: url('/img/slider/multimedia-1200.jpg');
}

.swiper-slide--8 {
  background-image: url('/img/slider/regies-mobiles-1200.jpg');
}

.home__flightcase {
  position: relative; background-color: #777;
  background-image: url('/img/hero-1200.jpg');
  background-size: cover;
  background-position: center;
  height: 500px;
  background-blend-mode: multiply;
}

.home__menuiserie {
  position: relative; background-color: #777;
  background-image: url('/img/menuiserie.jpg');
  background-size: cover;
  background-position: center;
  height: 500px;
  background-blend-mode: multiply;
}

.home__about {
  position: relative; background-color: #777;
  background-image: url('/img/about.jpg');
  background-size: cover;
  background-position: center;
  height: 500px;
  background-blend-mode: multiply;
}

.realisons {
  position: relative; background-color: #777;
  background-image: url('/img/realisons-votre-projet.jpg');
  background-size: cover;
  background-position: center;
  height: 200px;
  background-blend-mode: multiply;
}

.swiper-container {
  width: 100%;
  height: 300px;
}

.clients-list {
  list-style: none;
  padding-left: 0;
  font-size: .7em;
}

.featurette {
  margin-top: 25px;
  margin-bottom: 25px;
}

.section {
  margin-top: 50px;
}

.inner-section {
  margin-top: 1rem;
}

.section h1 {
  margin-bottom: 1rem;
}

@media (min-width: 800px) {
  .swiper-container {
    height: 700px;
  }

  .realisons {
    background-image: url('/img/realisons-votre-projet-800.jpg');
    height: 500px;
  }

  .swiper-slide--1 {
    background-image: url('/img/slider/touring-800.jpg');
  }

  .swiper-slide--2 {
    background-image: url('/img/slider/instruments-de-musique-800.jpg');
  }

  .swiper-slide--3 {
    background-image: url('/img/slider/synthetiseurs-800.jpg');
  }

  .swiper-slide--4 {
    background-image: url('/img/slider/velo-cargo-800.jpg');
  }

  .swiper-slide--5 {
    background-image: url('/img/slider/vinyls-800.jpg');
  }

  .swiper-slide--6 {
    background-image: url('/img/slider/installation-fixe-800.jpg');
  }

  .clients-list {
    columns: 2;
    column-gap: 100px;
  }


  .featurette {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .section {
    margin-top: 100px;
  }

  .inner-section {
    margin-top: 25px;
  }

  .section h1 {
    margin-bottom: 25px;
  }
}

@media (min-width: 1200px) {
  .realisons {
    background-image: url('/img/realisons-votre-projet-1200.jpg');
  }

  .swiper-slide--1 {
    background-image: url('/img/slider/touring-1200.jpg');
  }

  .swiper-slide--2 {
    background-image: url('/img/slider/instruments-de-musique-1200.jpg');
  }

  .swiper-slide--3 {
    background-image: url('/img/slider/synthetiseurs-1200.jpg');
  }

  .swiper-slide--4 {
    background-image: url('/img/slider/velo-cargo-1200.jpg');
  }

  .swiper-slide--5 {
    background-image: url('/img/slider/vinyls-1200.jpg');
  }

  .swiper-slide--6 {
    background-image: url('/img/slider/installation-fixe-1200.jpg');
  }

  .clients-list {
    columns: 3;
    column-gap: 100px;
  }

  .featurette {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .section {
    margin-top: 200px;
  }

  .inner-section {
    margin-top: 50px;
  }

  .section h1 {
    margin-bottom: 50px;
  }
}
